import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Formik, Field, Form } from 'formik';

import Button from 'components/FormButton';
import Message from 'components/Message';
import { path } from 'ramda';
import { useActions } from './hooks';
import resetSchema from './validation';

import Input from './Input';
import { CenteredContainer, LoginTitle, FormContainer } from './styles';

const Reset = () => {
  const { resetErrors, resetPassword } = useActions();
  const error = useSelector(path(['session', 'error']));
  const success = useSelector(path(['session', 'success']));

  useEffect(() => {
    resetErrors();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleReset = async ({ email }, formikActions) => {
    resetPassword({ email });
    formikActions.setSubmitting(false);
  };

  return (
    <CenteredContainer>
      <LoginTitle>reset password</LoginTitle>
      <FormContainer>
        <Formik
          initialValues={{ email: '' }}
          validationSchema={resetSchema}
          onSubmit={handleReset}
          validateOnChange={false}
          render={({ isSubmitting, errors }) => (
            <Form>
              <Field
                name="email"
                render={({ field, form }) => (
                  <Input
                    name={field.name}
                    value={field.value}
                    isAlerts={Boolean(success || error)}
                    resetAlerts={resetErrors}
                    {...form}
                  />
                )}
              />
              <Button type="submit" text="send" withTopMargin disabled={isSubmitting || Object.keys(errors).length > 0} />
              {error && <Message type="error">{error}</Message>}
              {success && <Message type="success">{success}</Message>}
            </Form>
          )}
        />
      </FormContainer>
    </CenteredContainer>
  );
};

export default Reset;
