import React, { useMemo, useCallback } from 'react';
import { renderToString } from 'react-dom/server';
import { LineChart } from '@carbon/charts-react';
import PropTypes from 'prop-types';
import '@carbon/charts/styles.css';

import { ID, VALUE, PREV_VALUE, DATE, PERCENTAGE, ALL } from 'store/kpis';
import CloseIcon from 'assets/svg-icons/Cross';

import { parseData } from './utils';
import Tooltip from './Tooltip';
import { Container, Head, Button, Wrapper, Title } from './styles';

const KpiHistory = ({ appName, items, label, period, unit, onClose }) => {
  const data = useMemo(() => parseData(period, items || [], unit), [period, items, unit]);
  const getColor = useCallback((group) => (group.includes('Current') ? '#0060fb' : '#c5c5c5'), []);
  const options = useMemo(() => {
    const title = [label, unit === PERCENTAGE ? '(IN %)' : ''].filter(Boolean).join(' ');

    return {
      axes: {
        bottom: { mapsTo: 'date', scaleType: 'time' },
        left: { mapsTo: 'value', scaleType: 'linear', title },
      },
      toolbar: {
        enabled: false,
      },
      tooltip: {
        customHTML: (d) =>
          renderToString(
            <Tooltip
              date={d[0].date}
              prevDate={d[0].prevDate}
              value={d[0].value}
              values={d[0].labelValues}
              label={label}
              unit={unit}
              period={period}
            />
          ),
      },
      legend: {
        enabled: period !== ALL,
      },
      height: '100%',
      width: '100%',
      getFillColor: getColor,
      getStrokeColor: getColor,
    };
  }, [period, getColor, label, unit]);

  return (
    <Container>
      <Head>
        <Title>{appName}</Title>
        <Button type="button" onClick={onClose} aria-label="Close Button">
          <CloseIcon />
        </Button>
      </Head>
      <Wrapper>
        <LineChart data={data} options={options} />
      </Wrapper>
    </Container>
  );
};

KpiHistory.defaultProps = { unit: '' };
KpiHistory.propTypes = {
  appName: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      [ID]: PropTypes.string.isRequired,
      [VALUE]: PropTypes.number.isRequired,
      [PREV_VALUE]: PropTypes.number,
      [DATE]: PropTypes.string,
    }).isRequired
  ).isRequired,
  label: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  unit: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default KpiHistory;
