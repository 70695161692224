import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const MainTitle = styled.h1`
  margin-top: 15px;
  margin-bottom: 25px;
`;

export const App = styled.div`
  margin-bottom: 20px;
`;

export const Title = styled.h1`
  font-weight: 700;
  text-align: center;
  padding: 10px;
  letter-spacing: 1.06px;
  text-transform: uppercase;
`;
