import * as session from './session';
import * as apps from './apps';
import * as alleyeSales from './alleyeSales';
import * as alleyeDashboard from './alleyeDashboard';
import * as alleyeProviders from './alleyeProviders';
import * as kpis from './kpis';

export default {
  ...session,
  ...apps,
  ...alleyeSales,
  ...alleyeDashboard,
  ...alleyeProviders,
  ...kpis,
};
