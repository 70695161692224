import posed from 'react-pose';
import { Metrics } from 'theme';

const PoseAnimateContainer = posed.div({
  open: {
    height: ({ size }) => Metrics.appCardWidth + (size + 1) * 50,
  },
  close: {
    height: Metrics.appCardWidth,
  },
});

export default PoseAnimateContainer;
