import { FETCH_ALLEYE_PROVIDER_KPI_FAILED, FETCH_ALLEYE_PROVIDER_KPI_SUCCESS, LOADING_ALLEYE_PROVIDER_KPI } from './consts';

import initialState from '../initialState';

export default (state = initialState.alleyeDashboard, { type, payload = {} } = {}) => {
  switch (type) {
    case FETCH_ALLEYE_PROVIDER_KPI_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case FETCH_ALLEYE_PROVIDER_KPI_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload,
        error: '',
      };

    case LOADING_ALLEYE_PROVIDER_KPI:
      return {
        ...state,
        isLoading: true,
      };

    default:
      return state;
  }
};
