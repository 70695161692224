import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { prop } from 'ramda';

import { Container, MenuItem, FieldName, FieldValue, FieldValueWithLink } from './styles';

const ExtendedMenu = ({ app, isOpen }) => {
  const { status, payload } = app;

  const isUrl = useCallback(
    (value) => /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(value),
    []
  );

  const getDomain = useCallback((value) => value.replace(/https?:\/\//, '').split('/')[0], []);

  return (
    <Container pose={isOpen ? 'open' : 'close'}>
      <MenuItem>
        <FieldName>Status</FieldName>
        <FieldValue>{status}</FieldValue>
      </MenuItem>
      {payload.map((item) => (
        <MenuItem key={prop('_id', item)}>
          <FieldName>{item.key}</FieldName>
          {isUrl(item.value) ? (
            <FieldValueWithLink href={item.value} target="_blank" rel="noopener noreferrer">
              {getDomain(item.value)}
            </FieldValueWithLink>
          ) : (
            <FieldValue>{item.value}</FieldValue>
          )}
        </MenuItem>
      ))}
    </Container>
  );
};

ExtendedMenu.defaultProps = {
  isOpen: false,
};

ExtendedMenu.propTypes = {
  app: PropTypes.shape({
    status: PropTypes.string,
    payload: PropTypes.instanceOf(Array),
  }).isRequired,
  isOpen: PropTypes.bool,
};

export default ExtendedMenu;
