import qs from 'qs';

import { wrapperRequest } from './utils';
import { API_URL, GET } from './consts';

export const fetchAlleyeProviderKPI = (payload) =>
  wrapperRequest({
    url: `${API_URL}/alleye/dashboard?${qs.stringify(payload)}`,
    options: { method: GET },
  });
