import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="46" height="26" viewBox="0 0 46 26">
    <text
      fill="#65D8A2"
      fillRule="evenodd"
      fontFamily="IBMPlexSans-Light, IBM Plex Sans"
      fontSize="26"
      fontWeight="300"
      letterSpacing=".722"
      transform="translate(-485 -408)"
    >
      <tspan x="485" y="433">
        Aa1
      </tspan>
    </text>
  </svg>
);
