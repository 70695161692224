import styled from 'styled-components';

export const Container = styled.header`
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 55px;
  background: #fff;
`;
