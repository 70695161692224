import styled from 'styled-components';
import { PoseAnimateContainer, PoseAnimateItem } from './utils';

export const MenuItem = styled(PoseAnimateItem)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  height: 50px;
  width: 100%;
  border-top: 1px solid black;
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Container = styled(PoseAnimateContainer)`
  position: absolute;
  left: 5px;
  display: flex;
  flex-direction: column;
  width: calc(100% - 10px);
`;

export const FieldName = styled.p`
  font-weight: bold;
  line-height: 1.13;
  letter-spacing: 0.77px;
  text-transform: uppercase;
`;

export const FieldValue = styled.p`
  line-height: 1;
  letter-spacing: 1.06px;
`;

export const FieldValueWithLink = styled.a`
  line-height: 1;
  letter-spacing: 1.06px;
  text-decoration: underline;
  color: #000;
`;
