import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { baseTheme } from '../../theme';

const Container = styled.p`
  color: ${({ type }) => {
    if (type === 'error') return baseTheme.colors.font.error;
    if (type === 'success') return baseTheme.colors.font.success;
    return baseTheme.colors.font.default;
  }};
  font-size: ${({ small }) => (small === 1 ? '13px' : '15px')};
  text-align: left;
  margin: ${({ form }) => (form === 1 ? '0 0 3px 0' : '15px 0')};
`;

const Message = ({ type, small, form, children, className }) => (
  <Container type={type} small={small} form={form} className={className}>
    {children}
  </Container>
);

Message.defaultProps = {
  type: '',
  form: 0,
  small: 0,
  className: '',
};

Message.propTypes = {
  type: PropTypes.string,
  form: PropTypes.number,
  small: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Message;
