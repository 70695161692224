import styled from 'styled-components';
import { repeat } from 'ramda';

import { baseTheme, Metrics } from '../../theme';

const types = {
  default: 0,
  normal: 1,
  success: 2,
  warning: 3,
};

const getBackground = (type, action) => {
  const actions = {
    normal: ['#edecec', '#4a90e2', '#65d8a2', '#f57728'],
    hover: ['#f4f4f4', '#6ea6e7', '#83dfb4', '#f79253'],
    pressed: ['#bdbcbc', '#3b73b4', '#50ac81', '#c45f20'],
    disabled: ['#eeeeee', '#bdd7f4', '#c2efda', '#fcd1b6'],
    active: repeat('#fff', 5),
  };

  return actions[action][types[type]] || '#fff';
};

const getColor = (type) => ['#979797', '#003b68', '#005e18', '#6f2a08'][types[type]];

export default styled.button`
  ${({ width = '100%', height = '60px', isActive, mt = 0, mr = 0, mb = 0, ml = 0, color = 'default' }) => `
    width: ${width};
    height: ${height};
    margin: ${mt}px ${mr}px ${mb}px ${ml}px;
    border: none;
    border-radius: ${Metrics.components.default.borderRadius}px;
    background: ${getBackground(color, 'normal')};
    color: ${getColor(color)};
    font-size: ${baseTheme.font.sizes.button};
    font-weight: ${baseTheme.font.weights.medium};
    letter-spacing: 0.9px;
    line-height: 1.15;
    outline: none;
    cursor: pointer;
    transition: all .5s;
    &:hover {
      background: ${getBackground(color, 'hover')};
    };
    &:active {
      background: ${getBackground(color, 'pressed')};
    };
    &:disabled {
      background: ${getBackground(color, 'disabled')};
      color: ${baseTheme.colors.line};
      cursor: not-allowed
    };
    ${
      isActive
        ? `
      background: ${getBackground(color, 'active')} !important;
      border: 1px solid ${getBackground(color, 'pressed')};
    `
        : ''
    }
    &:focus {
      box-shadow: ${baseTheme.shadow.focus};
    };
  `}
`;
