export const PROVIDER_SEARCH = 'search';
export const PROVIDER_COUNTRY = 'country';
export const PROVIDER_ID = '_organization';
export const PROVIDER_NAME = 'name';
export const PRICE = 'price';
export const VAT = 'vat';
export const SALES = 'sales';

export const LABELS = {
  [PRICE]: 'price',
  [VAT]: 'vat (in %)',
  [SALES]: 'number of licenses',
};

export const PLACEHOLDERS = {
  [PRICE]: '98.00',
  [VAT]: '7.7',
  [SALES]: '3',
};

export const INIT_FORM_VALUES = {
  [PROVIDER_COUNTRY]: '',
  [PROVIDER_SEARCH]: '',
  [PROVIDER_ID]: '',
  [PRICE]: '',
  [VAT]: '',
  [SALES]: '',
};
