import React from 'react';
import PropTypes from 'prop-types';
import Sort from 'assets/svg-icons/Sort';
import SortAsc from 'assets/svg-icons/SortAsc';
import SortDesc from 'assets/svg-icons/SortDesc';
import { Wrapper, Label } from './styles';
import { SORT_DIR } from '../consts';

const SortButton = ({ name, sort, selected, sortDir }) => (
  <Wrapper selected={selected} onClick={sort}>
    <Label>{name}</Label>
    {selected && sortDir === SORT_DIR.ASC && <SortAsc />}
    {selected && sortDir === SORT_DIR.DESC && <SortDesc />}
    {!selected && <Sort />}
  </Wrapper>
);

SortButton.propTypes = {
  name: PropTypes.string.isRequired,
  sort: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  sortDir: PropTypes.string.isRequired,
};

export default SortButton;
