import React, { useCallback, useEffect, useState } from 'react';
import { prop } from 'ramda';
import { useFormik } from 'formik';
import { useActions } from 'components/Root/hooks';
import Dropdown from 'components/Dropdown';
import TextInput from 'components/TextInput';

import SortButton from './SortButton';
import { SortList, SearchForm } from './styles';
import { COUNTRY, SEARCH, SORT_BUTTONS, SORT_DIR } from './consts';
import { COUNTRIES } from '../consts';
import ProviderList from './ProviderList';

const Providers = () => {
  const [sortBy, setSortBy] = useState('');
  const [sortDir, setSortDir] = useState('ASC');
  const { fetchAlleyeProviderKPI } = useActions();

  const updateSort = useCallback(
    (key) => {
      if (key === sortBy) {
        setSortDir(sortDir === SORT_DIR.ASC ? SORT_DIR.DESC : SORT_DIR.ASC);
      } else {
        setSortBy(key);
        setSortDir(SORT_DIR.ASC);
      }
    },
    [sortBy, sortDir]
  );

  const { values, handleChange, submitForm, setFieldValue } = useFormik({
    initialValues: {
      [SEARCH]: '',
      [COUNTRY]: '',
    },
    onSubmit: fetchAlleyeProviderKPI,
  });

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      submitForm(e);
    },
    [submitForm]
  );

  useEffect(() => {
    fetchAlleyeProviderKPI(values);
  }, [fetchAlleyeProviderKPI, values]);

  return (
    <>
      <SearchForm onSubmit={handleSubmit}>
        <TextInput
          value={values[SEARCH]}
          name={SEARCH}
          onChange={handleChange}
          type="search"
          placeholder="search a provider"
          withBottomMargin
        />
        <Dropdown
          name={COUNTRY}
          withBottomMargin
          placeholder="select a country"
          list={COUNTRIES}
          getOptionId={prop('id')}
          getOptionLabel={prop('label')}
          selectedOption={values[COUNTRY]}
          onSelect={(v) => setFieldValue(COUNTRY, v)}
        />
      </SearchForm>
      <SortList>
        {SORT_BUTTONS.map((btn) => (
          <SortButton
            key={btn.id}
            name={btn.name}
            sort={() => updateSort(btn.value)}
            selected={btn.value === sortBy}
            sortDir={sortDir}
          />
        ))}
      </SortList>
      <ProviderList sortBy={sortBy} sortDir={sortDir} />
    </>
  );
};

export default Providers;
