export const FEATURE_NAME = 'kpis';

export const ID = '_id';
export const LABEL = 'label';
export const VALUE = 'value';
export const PREV_VALUE = 'prevValue';
export const DATE = 'date';
export const PERIOD = 'period';
export const ALL = 'ALL';
export const YEARLY = 'YEARLY';
export const MONTHLY = 'MONTHLY';
export const NAME = 'name';
export const UNIT = 'unit';
export const CHF = 'CHF';
export const PERCENTAGE = 'Percentage';
