import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ selected }) => (selected ? '#23c47b' : '#ece9e9')};
  width: 100%;
  height: 30px;
  margin-right: 5px;
  padding: 0 0 0 10px;
  border-radius: 50px;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    background: ${({ selected }) => (selected ? '#23c47b' : '#d7d5d5')};
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

export const Label = styled.p`
  line-height: 1.4;
  letter-spacing: 1.06px;
  font-weight: 500;
  text-transform: uppercase;
  margin-right: 10px;
`;
