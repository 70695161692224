export default {
  session: {
    auth: Boolean(localStorage.token),
    user: null,
    isLogin: false,
    error: '',
    success: '',
  },
  apps: {
    data: [],
    error: '',
    isLoading: false,
    updateTime: '',
  },
  alleyeSales: {
    data: {},
    error: '',
    isLoading: false,
  },
  alleyeDashboard: {
    data: [],
    error: '',
    isLoading: false,
  },
  alleyeProviders: {
    data: [],
    error: '',
    isLoading: false,
  },
};
