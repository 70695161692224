import styled from 'styled-components';

import PoseAnimateContainer from './utils';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Username = styled.p`
  margin-left: 15px;
  font-size: 18px;
  line-height: 1.22;
  letter-spacing: 0.5px;
`;

export const AnimateContainer = styled(PoseAnimateContainer)`
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 1;
  overflow: hidden;
  background: white;
`;
