import React from 'react';
import PropTypes from 'prop-types';

import { Container, DownImgLine, UpImgLine } from './styles';

const MenuButton = ({ open, onClick }) => (
  <Container onClick={onClick}>
    <UpImgLine pose={open ? 'openUp' : 'closeUp'} />
    <DownImgLine pose={open ? 'openDown' : 'closeDown'} />
  </Container>
);

MenuButton.defaultProps = {
  open: false,
};

MenuButton.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default MenuButton;
