import React, { useMemo } from 'react';
import { cond, equals, T } from 'ramda';
import PropTypes from 'prop-types';

import { CHF, PERCENTAGE, ALL } from 'store/kpis';

import DatePeriod from './DatePeriod';
import { ValueWrapper, Value, TimelineWrapper } from './styles';

const KPIValue = ({ start, end, period, prev, unit, value }) => {
  const hasChange = useMemo(() => period && period !== ALL, [period]);

  const valueString = useMemo(
    () =>
      cond([
        [
          equals(CHF),
          (u, v) => {
            const result = v / 100;

            return result >= 10000 ? `${u} ${Math.round((result / 1000) * 10) / 10}k` : `${u} ${Math.round(result)}`;
          },
        ],
        [equals(PERCENTAGE), (u, v) => `${(v * 100).toFixed(1)}%`],
        [T, (u, v) => `${u ? `${u} ` : ''}${v}`],
      ])(unit, value || 0),
    [unit, value]
  );

  return (
    <ValueWrapper prev={prev}>
      <Value>{valueString}</Value>
      {hasChange && (
        <TimelineWrapper>
          <DatePeriod start={start} end={end} prev={prev} />
        </TimelineWrapper>
      )}
    </ValueWrapper>
  );
};

KPIValue.defaultProps = {
  start: null,
  end: null,
  unit: null,
  period: ALL,
  prev: false,
};

KPIValue.propTypes = {
  start: PropTypes.string,
  end: PropTypes.string,
  unit: PropTypes.string,
  value: PropTypes.number.isRequired,
  period: PropTypes.string,
  prev: PropTypes.bool,
};

export default KPIValue;
