import posed from 'react-pose';

const time1 = 100;
const time2 = 400;
const time3 = 250;
const time4 = 250;
const ease = 'easeOut';

const UpImg = posed.div({
  openUp: {
    top: 11,
    transform: 'rotate(-45deg)',
    transition: {
      top: {
        duration: time1,
        ease,
      },
      transform: {
        duration: time2,
        delay: time1,
        ease,
      },
    },
  },
  closeUp: {
    top: 18,
    transform: 'rotate(0deg)',
    transition: {
      top: {
        duration: time4,
        delay: time3,
        ease,
      },
      transform: {
        duration: time3,
        ease,
      },
    },
  },
});

const DownImg = posed.div({
  openDown: {
    top: 11,
    transform: 'rotate(45deg)',
    transition: {
      top: {
        duration: time1,
        ease,
      },
      transform: {
        duration: time2,
        delay: time1,
        ease,
      },
    },
  },
  closeDown: {
    top: 0,
    transform: 'rotate(0deg)',
    transition: {
      top: {
        duration: time4,
        delay: time3,
        ease,
      },
      transform: {
        duration: time3,
        ease,
      },
    },
  },
});

export { UpImg, DownImg };
