import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="11" viewBox="0 0 17 11">
    <g fill="none" fillRule="evenodd" stroke="#7c7c7c">
      <path d="M10.51 0l5.51 5.51-5.51 5.51" />
      <path fillRule="nonzero" d="M15.774 5.4H.891" />
    </g>
  </svg>
);
