import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import TextInput from 'components/TextInput';

const Input = ({ name, value, errors, setErrors, setFieldValue, isAlerts, resetAlerts }) => {
  const handleChange = useCallback(
    (text) => {
      if (isAlerts) {
        resetAlerts();
      }
      if (errors[name]) {
        setErrors({});
      }
      setFieldValue(name, text);
    },
    [errors, isAlerts, name, resetAlerts, setErrors, setFieldValue]
  );

  return (
    <TextInput
      name={name}
      value={value}
      handleChangeText={handleChange}
      error={errors[name]}
      placeholder="email"
      type="email"
      withBottomMargin
    />
  );
};

Input.propTypes = {
  name: PropTypes.oneOf(['email']).isRequired,
  value: PropTypes.string.isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  setErrors: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isAlerts: PropTypes.bool.isRequired,
  resetAlerts: PropTypes.func.isRequired,
};

export default Input;
