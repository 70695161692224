import posed from 'react-pose';

const PoseAnimateContainer = posed.div({
  openMenu: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: 'auto',
    delayChildren: 200,
    staggerChildren: 50,
    transition: {
      duration: 50,
    },
  },
  closeMenu: {
    top: 0,
    bottom: 0,
    height: 0,
    transition: {
      duration: 0,
    },
  },
});

export default PoseAnimateContainer;
