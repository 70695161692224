import { put, call, takeLeading } from 'redux-saga/effects';
import api from 'api';

import dictionary from 'utils/dictionary.json';

import { POST_ALLEYE_SALES, LOADING_ALLEYE_SALES, POST_ALLEYE_SALES_FAILED, POST_ALLEYE_SALES_SUCCESS } from './consts';

function* postAlleyeSales({ payload }) {
  try {
    yield put({ type: LOADING_ALLEYE_SALES });
    const response = yield call(api.postAlleyeSales, payload);
    if (response.error) {
      yield put({ type: POST_ALLEYE_SALES_FAILED, payload: response.error });
    } else {
      yield put({ type: POST_ALLEYE_SALES_SUCCESS, payload: response });
    }
  } catch (error) {
    yield put({ type: POST_ALLEYE_SALES_FAILED, payload: dictionary.defaultError });
  }
}
export default function* main() {
  yield takeLeading(POST_ALLEYE_SALES, postAlleyeSales);
}
