import {
  FETCH_ALLEYE_PROVIDERS_SUCCESS,
  FETCH_ALLEYE_PROVIDERS_FAILED,
  LOADING_ALLEYE_PROVIDERS,
  RESET_ALLEYE_PROVIDERS,
} from './consts';

import initialState from '../initialState';

export default (state = initialState.alleyeProviders, { type, payload = {} } = {}) => {
  switch (type) {
    case FETCH_ALLEYE_PROVIDERS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case FETCH_ALLEYE_PROVIDERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload,
        error: '',
      };

    case RESET_ALLEYE_PROVIDERS:
      return {
        ...state,
        data: [],
      };

    case LOADING_ALLEYE_PROVIDERS:
      return {
        ...state,
        isLoading: true,
      };

    default:
      return state;
  }
};
