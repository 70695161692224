import styled from 'styled-components';

import { baseTheme } from 'theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: ${baseTheme.colors.white};
  z-index: 10000;
`;
