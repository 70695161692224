/* eslint-disable react/no-array-index-key */
import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import { path, prop } from 'ramda';
import qs from 'qs';

import TextInput from 'components/TextInput';
import Button from 'components/FormButton';
import Message from 'components/Message';
import Link from 'components/Link';

import { guidelines } from './utils';
import { validationSchema } from './validation';
import { CenteredContainer, LoginTitle, FormContainer, LinkWrapper, Label, Item, Text } from './styles';
import { useActions } from './hooks';

const SetPassword = ({ location }) => {
  const token = prop('?token', qs.parse(location.search));
  const { resetPassword, resetErrors } = useActions();
  const error = useSelector(path(['session', 'error']));
  const success = useSelector(path(['session', 'success']));

  const initialValues = { password: '', token };

  useEffect(() => {
    resetErrors();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleReset = useCallback(
    (values, formikActions) => {
      resetPassword(values);
      formikActions.setSubmitting(false);
    },
    [resetPassword]
  );

  return (
    <CenteredContainer>
      <LoginTitle>set new password</LoginTitle>
      <FormContainer>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleReset}
          validateOnChange={false}
          render={({ isSubmitting, errors }) => (
            <Form>
              <Field
                name="password"
                render={({ field }) => (
                  <TextInput {...field} placeholder="password" type="password" withBottomMargin error={errors.password} />
                )}
              />
              <Button type="submit" text="continue" disabled={success || isSubmitting} />
              {(errors.token || error) && <Message type="error">{errors.token || error}</Message>}
              {success && <Message type="success">{success}</Message>}
              <LinkWrapper>
                <Link to="/login" name="log in?" />
              </LinkWrapper>
            </Form>
          )}
        />
        <Label>password guidelines</Label>
        {guidelines.map(({ icon: Icon, text }, i) => (
          <Item key={i}>
            <Icon />
            <Text>{text}</Text>
          </Item>
        ))}
      </FormContainer>
    </CenteredContainer>
  );
};

SetPassword.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
};

export default withRouter(SetPassword);
