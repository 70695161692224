/* eslint-disable no-unreachable */
import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { prop } from 'ramda';

import TextInput from 'components/TextInput';
import Button from 'components/Button';
import Message from 'components/Message';
import Spinner from 'components/FullScreenSpinner';
import Dropdown from 'components/Dropdown';
import { useActions } from 'components/Root/hooks';

import {
  LABELS,
  SALES,
  PLACEHOLDERS,
  PRICE,
  VAT,
  PROVIDER_ID,
  PROVIDER_COUNTRY,
  PROVIDER_SEARCH,
  PROVIDER_NAME,
  INIT_FORM_VALUES,
} from './consts';
import { useActions as useLocalActions } from './hooks';
import { validationSchema } from './validation';
import { FormContainer, ProviderWrapper, Provider, Label, SearchForm, ProviderList, SelectedProvider } from './styles';
import { COUNTRIES } from '../consts';

const Sales = () => {
  const [selectedProvider, setSelectedProvider] = useState();
  const { error: providersError, data: providers } = useSelector(prop('alleyeProviders'));
  const { error, isSubmitting, data } = useSelector(prop('alleyeSales'));
  const { fetchAlleyeProviders, resetAlleyeProviders } = useActions();
  const { postAlleyeSales, setIsLoadingAlleyeSales } = useLocalActions();

  const handleSales = useCallback(
    (results) => {
      setIsLoadingAlleyeSales();
      postAlleyeSales({ ...results });
    },
    [postAlleyeSales, setIsLoadingAlleyeSales]
  );

  const { values, handleChange, errors, submitForm, setFieldValue, resetForm } = useFormik({
    initialValues: INIT_FORM_VALUES,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema,
    onSubmit: handleSales,
  });

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      submitForm(e);
    },
    [submitForm]
  );

  const handleProviderSelection = useCallback(
    (provider) => {
      resetAlleyeProviders();
      setSelectedProvider(provider);
      setFieldValue(PROVIDER_ID, prop('_id', provider));
    },
    [resetAlleyeProviders, setFieldValue]
  );

  useEffect(() => {
    if (values[PROVIDER_COUNTRY] || values[PROVIDER_SEARCH]) {
      fetchAlleyeProviders(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAlleyeProviders, values[PROVIDER_COUNTRY], values[PROVIDER_SEARCH]]);

  useEffect(() => {
    if (data?.success) {
      resetForm({ values: INIT_FORM_VALUES });
    }
  }, [data, resetForm]);

  return (
    <FormContainer onSubmit={handleSubmit}>
      <SearchForm>
        <TextInput
          value={values[PROVIDER_SEARCH]}
          name={PROVIDER_SEARCH}
          onChange={handleChange}
          type="search"
          placeholder="search a provider"
          withBottomMargin
          error={providersError || errors[PROVIDER_ID]}
        />
        <Dropdown
          name={PROVIDER_COUNTRY}
          withBottomMargin
          placeholder="select a country"
          list={COUNTRIES}
          getOptionId={prop('id')}
          getOptionLabel={prop('label')}
          selectedOption={values[PROVIDER_COUNTRY]}
          onSelect={(v) => setFieldValue(PROVIDER_COUNTRY, v)}
        />
      </SearchForm>
      {providers.length > 0 && (
        <ProviderWrapper>
          <Label>select an Alleye provider</Label>
          <ProviderList>
            {providers.map((provider) => (
              <Provider
                key={prop('_id', provider)}
                isSelected={prop('_id', provider) === prop('_id', selectedProvider)}
                onClick={() => handleProviderSelection(provider)}
              >
                {provider.name}
              </Provider>
            ))}
          </ProviderList>
        </ProviderWrapper>
      )}
      {selectedProvider && <SelectedProvider>{selectedProvider[PROVIDER_NAME]}</SelectedProvider>}
      <TextInput
        label={LABELS[PRICE]}
        name={PRICE}
        value={values[PRICE]}
        onChange={handleChange}
        type="text"
        placeholder={PLACEHOLDERS[PRICE]}
        error={errors[PRICE]}
        withBottomMargin
      />
      <TextInput
        label={LABELS[VAT]}
        name={VAT}
        value={values[VAT]}
        onChange={handleChange}
        type="text"
        placeholder={PLACEHOLDERS[VAT]}
        error={errors[VAT]}
        withBottomMargin
      />
      <TextInput
        label={LABELS[SALES]}
        name={SALES}
        value={values[SALES]}
        onChange={handleChange}
        type="text"
        placeholder={PLACEHOLDERS[SALES]}
        error={errors[SALES]}
        withBottomMargin
      />
      <Button type="submit" disabled={isSubmitting} mt={24} height="50px">
        {isSubmitting ? <Spinner size={40} height="40px" /> : 'assign licenses'}
      </Button>
      {error && <Message type="error">{error}</Message>}
      {data?.success && <Message type="success">{data.success}</Message>}
    </FormContainer>
  );
};

export default Sales;
