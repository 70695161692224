import Icon1 from 'assets/svg-icons/Password1';
import Icon2 from 'assets/svg-icons/Password2';
import Icon3 from 'assets/svg-icons/Password3';

export const guidelines = [
  {
    icon: Icon1,
    text: 'always use a secure password. your password must consist of eight or more characters. medignition recommends that your password contains upper and lower case letters and at least one number.',
  },
  {
    icon: Icon2,
    text: 'do not use the password of medignition account for other online accounts.',
  },
  {
    icon: Icon3,
    text: 'change your password regularly and do not use the same password twice. medignition recommends changing the password annually.',
  },
];
