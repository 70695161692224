import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import { useAccessControl } from 'store/session/hooks';
import GlobalStyles from 'theme/global';
import Login from 'pages/Login';
import Reset from 'pages/Reset';
import SetPassword from 'pages/SetPassword';
import Apps from 'pages/Apps';
import KPI from 'pages/KPI';
import Alleye from 'pages/Alleye';

import InitApp from './InitApp';
import PrivateRoute from './PrivateRoute';

const Root = () => {
  const { pathname } = useLocation();
  const hasAlleyeAccess = useAccessControl('alleye');

  useEffect(() => {
    if (window.scrollTo) window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <GlobalStyles />
      <InitApp>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/reset" component={Reset} />
          <Route path="/setpassword" component={SetPassword} />
          <PrivateRoute exact path="/" component={KPI} />
          <PrivateRoute exact path="/systems" component={Apps} />
          {hasAlleyeAccess && <PrivateRoute path="/alleye" component={Alleye} />}
          <Redirect to="/" />
        </Switch>
      </InitApp>
    </>
  );
};

export default Root;
