import styled from 'styled-components';
import PoseAnimateItem from './utils';

export const AnimateItem = styled(PoseAnimateItem)`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20%;
  outline: none;
  font-size: 30px;
  font-weight: 500;
  border: none;
  background: white;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  text-transform: uppercase;
  &:hover {
    cursor: pointer;
  }
  &:first-child {
    border-top: 2px solid black;
  }
  &:last-child {
    border-bottom: 2px solid black;
  }
`;
