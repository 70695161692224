import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { any, includes, __ } from 'ramda';

import { getUserRoles } from './selectors';

export const useAccessControl = (name) => {
  const roles = useSelector(getUserRoles);
  const permissions = useMemo(
    () =>
      roles
        ? {
            alleye: any(includes(__, roles), ['MEDIGNITION', 'OCULOCARE']),
          }
        : {},
    [roles]
  );

  return Boolean(name && permissions[name]);
};
