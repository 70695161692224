import styled from 'styled-components';

export const Title = styled.h1`
  margin-top: 15px;
`;

export const StatusString = styled.p`
  margin-bottom: 25px;
  font-weight: 700;
  text-transform: uppercase;
`;
