import styled from 'styled-components';

export const TimelineWrapper = styled.div``;

export const Value = styled.p`
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 1.06px;
`;

export const ValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: ${({ prev }) => (prev ? 'right' : 'left')};

  & * {
    color: ${({ prev }) => (prev ? '#7c7c7c' : '#000')};
  }
`;
