import MD5 from 'crypto-js/md5';
import { prop } from 'ramda';

import { wrapperRequest } from './utils';
import { URL, POST } from './consts';

export const restore = () =>
  wrapperRequest({
    url: `${URL}/session`,
  });

export const login = (body = {}) =>
  wrapperRequest({
    url: `${URL}/login`,
    options: {
      method: POST,
      body: {
        ...body,
        password: MD5(prop('password', body)).toString(),
      },
    },
  });

export const sendResetLink = (body = {}) =>
  wrapperRequest({
    url: `${URL}/reset`,
    options: { method: POST, body },
  });

export const resetPassword = (body = {}) =>
  wrapperRequest({
    url: `${URL}/setpassword`,
    options: {
      method: POST,
      body: {
        ...body,
        password: MD5(prop('password', body)).toString(),
      },
    },
  });
