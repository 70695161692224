import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { baseTheme } from '../../theme';

export const StyledLink = styled(Link)`
  font-size: 15px;
  color: ${baseTheme.colors.font.link};
  letter-spacing: 0.33px;

  &:hover {
    color: ${baseTheme.colors.font.linkHover};
  }

  &:active {
    color: ${baseTheme.colors.font.linkPress};
  }
`;

const CustomLink = ({ name, to }) => <StyledLink to={to}>{name}</StyledLink>;

CustomLink.propTypes = {
  name: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default CustomLink;
