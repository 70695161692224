import React, { useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import { KPI_HISTORY, types } from './consts';
import { Container } from './styles';

const Portal = ({ type, children }) => {
  const ref = useRef(document.createElement('div'));

  useEffect(() => {
    const html = document.querySelector('html');
    const wrapper = ref.current;
    const portal = document.querySelector(types[type]);
    html.style.overflow = 'hidden';
    portal.appendChild(wrapper);

    return () => {
      portal.removeChild(wrapper);
      html.style.overflow = 'auto';
    };
  }, [type]);

  return createPortal(<Container>{children}</Container>, ref.current);
};

Portal.propTypes = {
  type: PropTypes.oneOf([KPI_HISTORY]).isRequired,
  children: PropTypes.node.isRequired,
};

export { KPI_HISTORY };

export default Portal;
