import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { path } from 'ramda';
import PropTypes from 'prop-types';

import ErrorMessage from 'components/ErrorMessage';

import Card from './Card';
import { WrapContainer, Container } from './styles';

const AppsTable = ({ apps }) => {
  const error = useSelector(path(['apps', 'error']));
  const [activeId, setActiveId] = useState(null);

  const handleCardClick = useCallback(
    (id) => {
      if (activeId === id) {
        setActiveId(null);
      } else {
        setActiveId(id);
      }
    },
    [activeId, setActiveId]
  );

  return (
    <WrapContainer>
      <Container>
        {error && <ErrorMessage message={error} />}
        {apps.map((app, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Card key={index} app={app} isOpen={activeId === index} onClick={() => handleCardClick(index)} />
        ))}
      </Container>
    </WrapContainer>
  );
};

AppsTable.propTypes = {
  apps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default AppsTable;
