import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  html, body {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    min-width: 350px;
  }
  body, #root {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "IBM Plex Sans", sans-serif;
    color: #383838;
  }
`;
