import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { AnimateItem } from './styles';

const ItemMenu = ({ to, redirectTo, toCloseMenu, disabled, name }) => {
  const handleRedirect = useCallback(() => {
    if (disabled) {
      toCloseMenu();
      return;
    }
    redirectTo(to);
  }, [disabled, toCloseMenu, redirectTo, to]);

  return (
    <AnimateItem type="button" onClick={handleRedirect}>
      {name}
    </AnimateItem>
  );
};

ItemMenu.defaultProps = {
  toCloseMenu: () => {},
};

ItemMenu.propTypes = {
  to: PropTypes.string.isRequired,
  redirectTo: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  toCloseMenu: PropTypes.func,
};

export default ItemMenu;
