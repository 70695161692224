import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { pipe } from 'ramda';

import { postAlleyeSales, setIsLoadingAlleyeSales } from 'store/alleyeSales/actions';

export const useActions = () => {
  const dispatch = useDispatch();

  return {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setIsLoadingAlleyeSales: useCallback(pipe(setIsLoadingAlleyeSales, dispatch), [setIsLoadingAlleyeSales, dispatch]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    postAlleyeSales: useCallback(pipe(postAlleyeSales, dispatch), [postAlleyeSales, dispatch]),
  };
};
