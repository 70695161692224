import styled from 'styled-components';

export const FormContainer = styled.form`
  width: 100%;
`;

export const SearchForm = styled.div`
  margin-bottom: 20px;
`;

export const ProviderWrapper = styled.div`
  margin-bottom: 20px;
`;

export const SelectedProvider = styled.p`
  width: 100%;
  height: 50px;
  padding: 0 27px 0 13px;
  border-radius: 8px;
  background-color: #23c47b;
  font-size: 18px;
  color: #fff;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const Label = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
`;

export const ProviderList = styled.ul`
  max-height: 180px;
  overflow-y: scroll;
  list-style: none;
  border: solid 1px #6a6a6a;
  border-radius: 8px;
  padding: 8px 0 8px 0;
`;

export const Provider = styled.li`
  min-height: 33px;
  display: flex;
  align-items: center;
  background: ${({ isSelected }) => (isSelected ? 'green' : 'white')};
  font-size: 18px;
  padding: 0 10px 0 15px;

  &:hover {
    background: #ece9e9;
    cursor: pointer;
  }
`;
