import { combineReducers } from 'redux';

import { FEATURE_NAME as alerts } from 'store/alerts/consts';
import alertsReducer from 'store/alerts/reducer';
import session from './session/sessionReducer';
import apps from './apps/appsReducer';
import alleyeSales from './alleyeSales/alleyeSalesReducer';
import alleyeDashboard from './alleyeDashboard/alleyeDashboardReducer';
import alleyeProviders from './alleyeProviders/alleyeProvidersReducer';

export default combineReducers({
  [alerts]: alertsReducer,
  session,
  apps,
  alleyeSales,
  alleyeDashboard,
  alleyeProviders,
});
