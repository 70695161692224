import React from 'react';
import { useHistory } from 'react-router-dom';

import SecondaryButton from 'components/SecondaryButton';

import Providers from './Providers';
import Sales from './Sales';
import { Header, Content, MainTitle } from './styles';

const Alleye = () => {
  const { location } = useHistory();

  return (
    <>
      <MainTitle>ALLEYE</MainTitle>
      <Header>
        <SecondaryButton to="/alleye">Providers</SecondaryButton>
        <SecondaryButton to="/alleye/sales">Sales</SecondaryButton>
      </Header>
      <Content>{location.pathname === '/alleye/sales' ? <Sales /> : <Providers />}</Content>
    </>
  );
};

export default Alleye;
