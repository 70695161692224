import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { baseTheme } from 'theme';

const Container = styled.p`
  font-size: 17px;
  text-align: center;
  color: ${baseTheme.colors.components.error};
`;

const ErrorMessage = ({ className, message }) => <Container className={className}>{message}</Container>;

ErrorMessage.defaultProps = { className: null };
ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ErrorMessage;
