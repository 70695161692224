export const SORT_BUTTONS = [
  {
    id: 1,
    name: 'tests',
    value: 'tests',
  },
  {
    id: 2,
    name: 'users',
    value: 'users',
  },
  {
    id: 3,
    name: 'licenses',
    value: 'sales',
  },
];

export const SORT_DIR = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const SEARCH = 'search';

export const COUNTRY = 'country';
