import { wrapperRequest } from './utils';
import { API_URL, POST } from './consts';

export const postAlleyeSales = (data) => {
  const { _organization, price, vat, sales } = data;
  return wrapperRequest({
    url: `${API_URL}/alleye/sales`,
    options: {
      method: POST,
      body: { _organization, price: parseFloat(price), vat: parseFloat(vat), sales: parseInt(sales, 10) },
    },
  });
};
