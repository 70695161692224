import { useDispatch } from 'react-redux';
import { pipe } from 'ramda';

import { resetErrors, resetPassword } from 'store/session/actions';

export const useActions = () => {
  const dispatch = useDispatch();

  return {
    resetPassword: pipe(resetPassword, dispatch),
    resetErrors: pipe(resetErrors, dispatch),
  };
};
