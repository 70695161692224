/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { CHF, PERCENTAGE, YEARLY, MONTHLY } from 'store/kpis';

import { FORMAT_DATE, VALUE_FORMAT } from './consts';
import { Li } from './styles';

const Tooltip = ({ date, prevDate, value, values, label, unit, period }) => {
  const [dateString, prefix, postFix, prevLabel] = useMemo(
    () => [
      FORMAT_DATE.format(prevDate || date),
      (unit === CHF && 'CHF ') || '',
      (unit === PERCENTAGE && '%') || '',
      (period === YEARLY && 'Previous year') || (period === MONTHLY && 'Previous month') || 'Previous',
    ],
    [date, period, prevDate, unit]
  );
  const items = useMemo(
    () =>
      values
        ? values.map((val, i) => [
            i === 0 ? label : prevLabel,
            `${prefix}${typeof val === 'number' ? val.toLocaleString('de-CH', VALUE_FORMAT) : ''}${postFix}`,
            i === 0 ? '#0060fb' : '#c5c5c5',
          ])
        : [
            [
              label,
              `${prefix}${typeof value === 'number' ? value.toLocaleString('de-CH', VALUE_FORMAT) : ''}${postFix}`,
              '#0060fb',
            ],
          ],
    [label, postFix, prefix, prevLabel, value, values]
  );

  return (
    <ul className="multi-tooltip">
      <li>
        <div className="datapoint-tooltip">
          <div className="label">
            <p>{dateString}</p>
          </div>
        </div>
      </li>
      {items.map(([l, v, c], key) => (
        <Li key={key} $color={c}>
          <div className="datapoint-tooltip">
            <div className="label">
              <p>{l}:</p>
            </div>
            <p className="value">{v}</p>
          </div>
        </Li>
      ))}
    </ul>
  );
};

Tooltip.defaultProps = {
  date: null,
  prevDate: null,
  values: null,
  unit: '',
  period: '',
};
Tooltip.propTypes = {
  date: PropTypes.instanceOf(Date),
  prevDate: PropTypes.instanceOf(Date),
  value: PropTypes.number.isRequired,
  values: PropTypes.arrayOf(PropTypes.number.isRequired),
  label: PropTypes.string.isRequired,
  unit: PropTypes.string,
  period: PropTypes.string,
};

export default Tooltip;
