import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { path } from 'ramda';
import PropTypes from 'prop-types';

import { logout } from 'store/session/actions';
import { useAccessControl } from 'store/session/hooks';

import ItemMenu from './ItemMenu';
import MenuButton from './MenuButton';
import { Container, Username, AnimateContainer } from './styles';

const MobileMenu = ({ currentPath, ...rest }) => {
  const [isOpen, setOpen] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const username = useSelector(path(['session', 'user', 'firstname']));
  const hasAlleyeAccess = useAccessControl('alleye');

  const handleMenuButtonClick = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen, setOpen]);

  const redirectTo = useCallback(
    (to) => {
      history.push(to);
      setOpen(false);
    },
    [history]
  );

  const handleLogout = useCallback(
    (to) => {
      dispatch(logout());
      history.push(to);
    },
    [history, dispatch]
  );

  return (
    <Container>
      {username && <Username>{`Hi ${username}!`}</Username>}
      <MenuButton open={isOpen} onClick={handleMenuButtonClick} />
      <AnimateContainer pose={isOpen ? 'openMenu' : 'closeMenu'} {...rest}>
        <ItemMenu name="dashboard" to="/" disabled={currentPath === '/'} redirectTo={redirectTo} />
        <ItemMenu name="systems" to="/systems" disabled={currentPath === '/systems'} redirectTo={redirectTo} />
        {hasAlleyeAccess && <ItemMenu name="alleye" to="/alleye" disabled={currentPath === '/alleye'} redirectTo={redirectTo} />}
        <ItemMenu name="logout" to="/login" disabled={currentPath === '/login'} redirectTo={handleLogout} />
      </AnimateContainer>
    </Container>
  );
};

MobileMenu.defaultProps = {
  isOpen: false,
  currentPath: '',
};

MobileMenu.propTypes = {
  isOpen: PropTypes.bool,
  currentPath: PropTypes.string,
};

export default MobileMenu;
