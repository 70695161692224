import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29">
    <g fill="none" fillRule="evenodd">
      <g stroke="#383838" strokeWidth="1.5">
        <g>
          <g>
            <g>
              <path
                d="M2 2L8 2 8 8"
                transform="translate(-96 -358) translate(13 358) translate(83) translate(9.914 7) rotate(-45 5 5)"
              />
              <path
                d="M2 7L8 7 8 13"
                transform="translate(-96 -358) translate(13 358) translate(83) translate(9.914 7) rotate(135 5 10)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
