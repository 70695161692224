import styled from 'styled-components';

export const Li = styled.li`
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    inset: 0 auto 0 -4px;
    width: 4px;
    background-color: ${({ $color }) => $color || 'none'};
  }
`;
