import React from 'react';

import { Container } from './styles';

const Footer = () => (
  <Container>
    <p>{`© ${new Date().getFullYear()} medignition. all rights reserved.`}</p>
  </Container>
);

export default Footer;
