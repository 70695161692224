import styled from 'styled-components';

export const Main = styled.main`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1024px;
  padding: 0 15px;
  margin: 0 auto;
  position: relative;
  font-size: 15px;
  h1 {
    font-size: 15px;
    font-weight: bold;
    line-height: 1.4;
    letter-spacing: 1.06px;
    margin-bottom: 7px 0;
  }
`;
