import { omit } from 'ramda';
import { handleActions } from 'redux-actions';

import { SUCCESS, ERROR, LOADING } from './consts';
import { SET_LOADING, SET_SUCCESS, SET_ERROR, SET_CANCEL } from './types';

export default handleActions(
  {
    [SET_LOADING]: (state, { payload }) => ({
      ...state,
      [payload]: { [LOADING]: true },
    }),
    [SET_SUCCESS]: (state, { payload }) => {
      const { id, success } = payload;

      return { ...state, [id]: { [SUCCESS]: success || true } };
    },
    [SET_ERROR]: (state, { payload }) => {
      const { id, error } = payload;

      return { ...state, [id]: { [ERROR]: error } };
    },
    [SET_CANCEL]: (state, { payload }) => (state[payload] ? omit([payload], state) : state),
  },
  {}
);
