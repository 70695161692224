import { LOADING_ALLEYE_SALES, POST_ALLEYE_SALES_FAILED, POST_ALLEYE_SALES_SUCCESS } from './consts';

import initialState from '../initialState';

export default (state = initialState.alleyeSales, { type, payload = {} } = {}) => {
  switch (type) {
    case POST_ALLEYE_SALES_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case POST_ALLEYE_SALES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload,
        error: '',
      };

    case LOADING_ALLEYE_SALES:
      return {
        ...state,
        isLoading: true,
      };

    default:
      return state;
  }
};
