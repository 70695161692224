import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useKpisActions, useAlerts, LABEL, VALUE, PREV_VALUE, PERIOD, UNIT, PERCENTAGE, ALL } from 'store/kpis';
import KPIValue from 'components/KPIValue';
import Spinner from 'components/FullScreenSpinner';
import Portal, { KPI_HISTORY } from 'components/Portal';

import KpiHistory from './KpiHistory';
import { Wrapper, TitleWrapper, Values, Label, Name, Period, Change, SpinnerWrap, Message } from './styles';

const Card = ({ id, kpi, appName }) => {
  const { fetchKpiHistory } = useKpisActions();
  const { action, loading, success, error, resetAlerts } = useAlerts(fetchKpiHistory);

  const growth = useMemo(() => {
    if (kpi[UNIT] === PERCENTAGE) return kpi[VALUE] - kpi[PREV_VALUE];

    return kpi[PREV_VALUE] > 0 ? (kpi[VALUE] - kpi[PREV_VALUE]) / kpi[PREV_VALUE] : null;
  }, [kpi]);

  const hasChange = useMemo(() => kpi[PERIOD] && kpi[PERIOD] !== ALL && kpi[PREV_VALUE] > 0, [kpi]);

  const handleClick = useCallback(() => action(id), [action, id]);

  return (
    <>
      {Boolean(success) && (
        <Portal type={KPI_HISTORY}>
          <KpiHistory
            appName={appName}
            items={success}
            label={kpi[LABEL]}
            period={kpi[PERIOD]}
            unit={kpi[UNIT]}
            onClose={resetAlerts}
          />
        </Portal>
      )}
      <Wrapper type="button" onClick={handleClick}>
        <TitleWrapper>
          <Label>
            <Name>{kpi[LABEL]}</Name>
            <Period>{kpi[PERIOD]}</Period>
          </Label>
          {hasChange && <Change isGrowing={growth >= 0}>{`${growth >= 0 ? '+' : ''}${Math.round(100 * growth)}%`}</Change>}
        </TitleWrapper>
        <Values>
          <KPIValue start={kpi.start} end={kpi.end} unit={kpi[UNIT]} value={kpi[VALUE]} period={kpi[PERIOD]} />
          {hasChange && (
            <KPIValue
              start={kpi.prevStart}
              end={kpi.prevEnd}
              unit={kpi[UNIT]}
              value={kpi[PREV_VALUE]}
              period={kpi[PERIOD]}
              prev
            />
          )}
        </Values>
        {loading && (
          <SpinnerWrap>
            <Spinner height="auto" />
          </SpinnerWrap>
        )}
      </Wrapper>
      {error && <Message message={error} />}
    </>
  );
};

Card.propTypes = {
  id: PropTypes.string.isRequired,
  kpi: PropTypes.shape({
    [LABEL]: PropTypes.string.isRequired,
    [VALUE]: PropTypes.number.isRequired,
    [UNIT]: PropTypes.string,
    [PERIOD]: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    [PREV_VALUE]: PropTypes.number,
    prevStart: PropTypes.string,
    prevEnd: PropTypes.string,
  }).isRequired,
  appName: PropTypes.string.isRequired,
};

export default Card;
