import React from 'react';

import { Container } from './styles';
import MobileMenu from './MobileMenu';

const Header = () => (
  <Container>
    <MobileMenu />
  </Container>
);

export default Header;
