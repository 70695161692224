import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { path } from 'ramda';
import { Redirect } from 'react-router-dom';

import TextInput from 'components/TextInput';
import Button from 'components/Button';
import Message from 'components/Message';
import Link from 'components/Link';
import Spinner from 'components/FullScreenSpinner';

import { EMAIL, PASSWORD } from './consts';
import { useActions } from './hooks';
import { validationSchema } from './validation';
import { CenteredContainer, LoginTitle, FormContainer, LinkContainer } from './styles';

const Login = () => {
  const error = useSelector(path(['session', 'error']));
  const isAuth = useSelector(path(['session', 'auth']));
  const isLogin = useSelector(path(['session', 'isLogin']));
  const { login, resetErrors, setIsLoginTrue } = useActions();

  const handleAuth = useCallback(
    (results) => {
      if (error) resetErrors();
      setIsLoginTrue();
      login({ ...results });
    },
    [login, error, resetErrors, setIsLoginTrue]
  );

  const { values, handleChange, errors, submitForm } = useFormik({
    initialValues: {
      [EMAIL]: '',
      [PASSWORD]: '',
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema,
    onSubmit: handleAuth,
  });

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      submitForm(e);
    },
    [submitForm]
  );

  if (isAuth) {
    return <Redirect to="/" />;
  }

  return (
    <CenteredContainer>
      <LoginTitle>medignition technology</LoginTitle>
      <FormContainer onSubmit={handleSubmit}>
        <TextInput
          name={EMAIL}
          value={values[EMAIL]}
          onChange={handleChange}
          type={EMAIL}
          placeholder="email"
          withBottomMargin
          error={errors[EMAIL]}
        />
        <TextInput
          name={PASSWORD}
          value={values[PASSWORD]}
          onChange={handleChange}
          type={PASSWORD}
          placeholder="password"
          showPasswordText="show"
          error={errors[PASSWORD]}
        />
        <Button type="submit" disabled={isLogin} mt={24} height="50px">
          {isLogin ? <Spinner size={40} height="40px" /> : 'login'}
        </Button>
        {error && <Message type="error">{error}</Message>}
        <LinkContainer>
          <Link to="/reset" name="forgot password?" />
        </LinkContainer>
      </FormContainer>
    </CenteredContainer>
  );
};

export default Login;
