import { FETCH_APPS_FAILED, FETCH_APPS_SUCCESS, SET_IS_LOADING_TRUE } from './consts';

import initialState from '../initialState';

export default (state = initialState.apps, { type, payload = {} } = {}) => {
  switch (type) {
    case FETCH_APPS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case FETCH_APPS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload,
        error: '',
        updateTime: payload[0].modified,
        lastFetch: new Date(),
      };

    case SET_IS_LOADING_TRUE:
      return {
        ...state,
        isLoading: true,
      };

    default:
      return state;
  }
};
