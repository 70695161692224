import styled from 'styled-components';

export const Container = styled.footer`
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  padding: 0 15px;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.31;
  letter-spacing: 0.66px;
`;
