import React from 'react';
import PropTypes from 'prop-types';

import { Select, Option } from './styles';

const CustomDropdown = ({ selectedOption, onSelect, list, placeholder, getOptionId, getOptionLabel, withBottomMargin }) => (
  <Select value={selectedOption} onChange={(e) => onSelect(e.target.value)} withBottomMargin={withBottomMargin}>
    {placeholder && (
      <Option value="" disabled defaultValue>
        {placeholder}
      </Option>
    )}
    {list.map((item) => (
      <Option key={getOptionId(item)} value={getOptionId(item)}>
        {getOptionLabel(item)}
      </Option>
    ))}
  </Select>
);

CustomDropdown.defaultProps = {
  selectedOption: null,
  withBottomMargin: false,
};

CustomDropdown.propTypes = {
  withBottomMargin: PropTypes.bool,
  getOptionId: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  selectedOption: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default CustomDropdown;
