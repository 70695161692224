import styled from 'styled-components';
import { UpImg, DownImg } from './utils';

export const Container = styled.div`
  position: absolute;
  top: 18px;
  right: 0;
  width: 10vw;
  height: 35px;
  z-index: 2;
  cursor: pointer;
`;

export const UpImgLine = styled(UpImg)`
  position: absolute;
  right: 10px;
  width: 35px;
  height: 2px;
  background: #000;
`;

export const DownImgLine = styled(DownImg)`
  position: absolute;
  right: 10px;
  width: 35px;
  height: 2px;
  background: #000;
`;
