import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { prop } from 'ramda';
import { useSelector } from 'react-redux';

import Spinner from 'components/FullScreenSpinner';
import ErrorMessage from 'components/ErrorMessage';

import { ProviderList, ErrorWrapper, Wrapper, ValueList, Value, Name } from './styles';
import { SORT_DIR } from '../consts';

const Provider = ({ sortBy, sortDir }) => {
  const { error, isLoading, data: providers } = useSelector(prop('alleyeDashboard'));

  const sortProviders = useCallback(() => {
    function compare(a, b) {
      if (a[sortBy] < b[sortBy] && sortDir === SORT_DIR.ASC) return -1;
      if (a[sortBy] > b[sortBy] && sortDir === SORT_DIR.ASC) return 1;
      if (a[sortBy] < b[sortBy] && sortDir === SORT_DIR.DESC) return 1;
      if (a[sortBy] > b[sortBy] && sortDir === SORT_DIR.DESC) return -1;
      return 0;
    }

    if (!sortBy) return providers;
    return providers.sort(compare);
  }, [providers, sortBy, sortDir]);

  if (error) {
    return (
      <ErrorWrapper>
        <ErrorMessage message={error} />
      </ErrorWrapper>
    );
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <ProviderList>
      {sortProviders().map((provider) => (
        <Wrapper key={prop('_id', provider)}>
          <Name>{provider.name}</Name>
          <ValueList>
            <Value selected={sortBy === 'tests'}>{provider.tests}</Value>
            <Value selected={sortBy === 'users'}>{provider.users}</Value>
            <Value selected={sortBy === 'sales'}>{provider.sales}</Value>
          </ValueList>
        </Wrapper>
      ))}
    </ProviderList>
  );
};

Provider.propTypes = {
  sortDir: PropTypes.string.isRequired,
  sortBy: PropTypes.string.isRequired,
};

export default Provider;
