import styled from 'styled-components';

import { baseTheme } from 'theme';

const { font, colors } = baseTheme;
const { background, fontColor, clickColor, hoverColor, disabledColor } = colors.components.button;

export const customTheme = {
  vPadding: '16px',
  fontSize: font.sizes.button,
  default: {
    color: background,
    fontColor,
    clickColor,
    hoverColor,
    disabledColor,
  },
};

export const Spacing = styled.div`
  margin-top: ${({ $withTopMargin }) => ($withTopMargin ? 30 : 0)}px;
  & label {
    margin-right: 40px;
  }
`;
