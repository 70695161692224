import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { StyledLink } from './styles';

const SecondaryButton = ({ children, to }) => {
  const { location } = useHistory();

  return (
    <StyledLink to={to} selected={to === location.pathname}>
      {children}
    </StyledLink>
  );
};

SecondaryButton.propTypes = {
  children: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default SecondaryButton;
