import colors from './colors';
import Metrics from './metrics';

const font = {
  family: '"IBM Plex Sans", sans-serif',
  sizes: {
    default: '18px',
    copy: '20px',
    emphasized: '30px',
    label: '15px',
    inputValue: '18px',
    button: '15px',
  },
  weights: {
    light: 300,
    regular: 'normal',
    medium: 500,
    semiBold: 600,
    bold: 'bold',
  },
};

const shadow = {
  focus: '0 0 5px rgba(0, 0, 0, .3)',
};

const baseTheme = {
  colors,
  font,
  shadow,
};

export { baseTheme, Metrics };
