import qs from 'qs';

import { wrapperRequest } from './utils';
import { API_URL, GET } from './consts';

export const fetchAlleyeProviders = (payload) =>
  wrapperRequest({
    url: `${API_URL}/alleye/providers?${qs.stringify(payload)}`,
    options: { method: GET },
  });
