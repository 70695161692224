import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TextInput } from '@medignition/medignition-ui';

import { baseTheme } from 'theme';
import SearchIcon from 'assets/svg-icons/Search';

export const editModeTheme = {
  label: {
    width: '200px',
    fontSize: baseTheme.font.sizes.default,
    fontWeight: baseTheme.font.weights.regular,
  },
  showPasswordLabel: {
    fontColor: baseTheme.colors.font.default,
  },
  showPasswordIcon: {
    border: `solid 2px ${baseTheme.colors.font.default}`,
    activeColor: baseTheme.colors.font.default,
    inactiveColor: baseTheme.colors.font.secondary,
  },
  searchIcon: {
    width: '35px',
    height: '35px',
    vPadding: '6px',
    hPadding: '0',
  },
  field: {
    border: `solid 1px ${baseTheme.colors.components.button.border}`,
    borderColor: baseTheme.colors.components.button.border,
    backgroundColor: '#fff',
    vPadding: '12px',
    hPadding: '10px',
    fontSize: baseTheme.font.sizes.default,
    fontWeight: baseTheme.font.weights.regular,
    fontColor: baseTheme.colors.font.default,
    placeholderColor: baseTheme.colors.font.placeholder,
  },
};

const readModeTheme = {
  ...editModeTheme,
  field: {
    fontSize: baseTheme.font.sizes.default,
    backgroundColor: baseTheme.colors.components.readMode,
    hPadding: '25px',
    vPadding: '8px',
    border: 'none',
    borderRadius: '8px',
  },
};

const errorTheme = {
  ...editModeTheme,
  field: {
    ...editModeTheme.field,
    fontColor: baseTheme.colors.font.error,
    borderColor: '#ff5f5f',
  },
};

const Spacing = styled.div`
  margin-bottom: ${({ last, withBottomMargin }) => (last || withBottomMargin ? 10 : 0)}px;

  & > div > div {
    display: ${({ type }) => (type === 'search' || type === 'password' ? 'default' : 'block')};
  }
  & label {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  & input {
    opacity: 1;
  }
  & input::placeholder {
    color: ${baseTheme.colors.font.placeholder};
  }
  & input:focus {
    box-shadow: inset 0 0 5px 0 #6e6e6e;
  }
  & > div > div > div {
    right: 10px;
  }
`;

export const ErrorMessage = styled.p`
  margin-top: 3px;
  margin-left: 10px;
  color: ${baseTheme.colors.font.error};
  font-size: ${baseTheme.font.sizes.button};
  word-break: break-word;
`;

const CustomTextInput = ({ type, last, withBottomMargin, readMode, error, ...rest }) => {
  const customTheme = (error && errorTheme) || (readMode && readModeTheme) || editModeTheme;

  return (
    <Spacing last={last} type={type} withBottomMargin={withBottomMargin}>
      <TextInput customTheme={customTheme} disabled={readMode} type={type} renderSearchIcon={() => <SearchIcon />} {...rest} />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Spacing>
  );
};

CustomTextInput.defaultProps = {
  type: 'text',
  last: false,
  withBottomMargin: false,
  readMode: false,
  error: null,
  labelMargin: null,
};

CustomTextInput.propTypes = {
  type: PropTypes.string,
  last: PropTypes.bool,
  withBottomMargin: PropTypes.bool,
  readMode: PropTypes.bool,
  error: PropTypes.string,
  labelMargin: PropTypes.string,
};

export default CustomTextInput;
