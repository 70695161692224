export const AUTHENTICATED = 'AUTHENTICATED';
export const AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED';
export const LOGIN = 'LOGIN';
export const RESET_ERRORS = 'RESET_ERRORS';
export const SET_IS_LOGIN_TRUE = 'SET_IS_LOGIN_TRUE';
export const RESET_PASSWORD_LINK_FAILED = 'RESET_PASSWORD_LINK_FAILED';
export const RESET_PASSWORD_LINK_SENT = 'RESET_PASSWORD_LINK_SENT';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SET_PASSWORD = 'SET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
export const LOGOUT = 'LOGOUT';
export const RESTORE_SESSION = 'RESTORE_SESSION';
export const RESTORE_SESSION_SUCCESS = 'RESTORE_SESSION_SUCCESS';
export const RESTORE_SESSION_FAILED = 'RESTORE_SESSION_FAILED';
