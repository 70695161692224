import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { any, includes, __ } from 'ramda';

import { getUserRoles } from 'store/session/selectors';

const COMPANIES = [
  {
    id: '',
    companyName: 'All Brands',
    roles: [],
  },
  {
    id: 'Oculocare medical',
    companyName: 'Oculocare medical',
    roles: ['MEDIGNITION', 'OCULOCARE'],
  },
  {
    id: 'Augenmobil',
    companyName: 'Augenmobil',
    roles: ['MEDIGNITION', 'AUGENMOBIL'],
  },
  {
    id: 'CuraData',
    companyName: 'CuraData',
    roles: ['MEDIGNITION', 'CURADATA'],
  },
  {
    id: 'EECY',
    companyName: 'EECY',
    roles: ['MEDIGNITION', 'EECY', 'FINTOOL'],
  },
  {
    id: 'appertus',
    companyName: 'appertus',
    roles: ['MEDIGNITION', 'APPERTUS', 'TMS_TRAINER'],
  },
  {
    id: 'medignition',
    companyName: 'medignition',
    roles: ['MEDIGNITION'],
  },
  {
    id: 'meditrova',
    companyName: 'meditrova',
    roles: ['MEDIGNITION', 'MEDITROVA'],
  },
  {
    id: 'Other',
    companyName: 'Other',
    roles: ['MEDIGNITION'],
  },
];

export const useFilteredCompanies = () => {
  const userRoles = useSelector(getUserRoles);

  return useMemo(() => COMPANIES.filter(({ roles }) => roles.length === 0 || any(includes(__, userRoles), roles)), [userRoles]);
};
