import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="26" viewBox="0 0 35 26">
    <text
      fill="#65D8A2"
      fillRule="evenodd"
      fontFamily="IBMPlexSans-Light, IBM Plex Sans"
      fontSize="26"
      fontWeight="300"
      letterSpacing=".722"
      transform="translate(-486 -558)"
    >
      <tspan x="485" y="583.286">
        1
      </tspan>
      <tspan x="501.01" y="583" fontFamily="IBMPlexSans, IBM Plex Sans" fontWeight="normal">
        ↺
      </tspan>
    </text>
  </svg>
);
