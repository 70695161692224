import styled from 'styled-components';

export const DateText = styled.p`
  width: fit-content;
`;

export const Arrow = styled.span`
  margin: 0 7px;
  display: inline-block;
`;
