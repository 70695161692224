import styled from 'styled-components';

import { baseTheme } from 'theme';
import ErrorMessage from 'components/ErrorMessage';

export const Wrapper = styled.button`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  margin-bottom: 10px;
  width: 100%;
  padding: 20px;
  background: #ece9e9;
  outline: none;
  border: none;
  cursor: pointer;
  position: relative;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  align-items: center;
  width: 100%;
`;

export const Values = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  width: 100%;
  font-size: ${baseTheme.font.sizes.button};
  margin-top: 10px;
`;

export const Label = styled.p`
  text-transform: uppercase;
  letter-spacing: 1.06px;
  text-align: left;
`;

export const Name = styled.span`
  font-size: ${baseTheme.font.sizes.button};
  margin-right: 10px;
`;

export const Period = styled.span`
  font-size: ${baseTheme.font.sizes.button};
  text-transform: uppercase;
  letter-spacing: 1.06px;
  color: #7c7c7c;
  display: inline-block;
`;

export const Change = styled.p`
  background: ${({ isGrowing }) => (isGrowing ? '#5effb6' : '#ff5f5f')};
  font-size: ${baseTheme.font.sizes.button};
  color: ${({ isGrowing }) => (isGrowing ? '#000' : '#fff')};
  padding: 5px 10px;
  border-radius: 16px;
  margin-left: 10px;
`;

export const SpinnerWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.6);
`;

export const Message = styled(ErrorMessage)`
  margin-top: -10px;
`;
