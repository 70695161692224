import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { pipe } from 'ramda';

import { login, resetErrors, setIsLoginTrue } from 'store/session/actions';

export const useActions = () => {
  const dispatch = useDispatch();

  return {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setIsLoginTrue: useCallback(pipe(setIsLoginTrue, dispatch), [setIsLoginTrue, dispatch]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    login: useCallback(pipe(login, dispatch), [login, dispatch]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    resetErrors: useCallback(pipe(resetErrors, dispatch), [resetErrors, dispatch]),
  };
};
