import styled from 'styled-components';

export const ProviderList = styled.div`
  margin-top: 60px;
`;

export const ErrorWrapper = styled.div`
  margin-top: 60px;
`;

export const Wrapper = styled.div`
  border-top: solid 1px #6a6a6a;
  padding-top: 5px;
  margin-bottom: 20px;
`;

export const Name = styled.p`
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 1.06px;
  text-align: center;
  text-transform: uppercase;
`;

export const ValueList = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const Value = styled.p`
  display: flex;
  align-items: center;
  background: ${({ selected }) => (selected ? '#5effb6' : '#ece9e9')};
  width: 100%;
  height: 30px;
  margin-right: 5px;
  padding: 0 10px 0 10px;
  border-radius: 16px;

  &:last-of-type {
    margin-right: 0;
  }
`;
