import { put, call, debounce } from 'redux-saga/effects';
import api from 'api';

import dictionary from 'utils/dictionary.json';

import {
  LOADING_ALLEYE_PROVIDER_KPI,
  FETCH_ALLEYE_PROVIDER_KPI_FAILED,
  FETCH_ALLEYE_PROVIDER_KPI_SUCCESS,
  FETCH_ALLEYE_PROVIDER_KPI,
} from './consts';

function* fetchAlleyeProviderKPI({ payload }) {
  try {
    yield put({ type: LOADING_ALLEYE_PROVIDER_KPI });
    const response = yield call(api.fetchAlleyeProviderKPI, payload);
    if (response.error) {
      yield put({ type: FETCH_ALLEYE_PROVIDER_KPI_FAILED, payload: response.error });
    } else {
      yield put({ type: FETCH_ALLEYE_PROVIDER_KPI_SUCCESS, payload: response });
    }
  } catch (error) {
    yield put({ type: FETCH_ALLEYE_PROVIDER_KPI_FAILED, payload: dictionary.defaultError });
  }
}
export default function* main() {
  yield debounce(200, FETCH_ALLEYE_PROVIDER_KPI, fetchAlleyeProviderKPI);
}
