import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29">
    <g fill="none" fillRule="evenodd">
      <g stroke="#383838" strokeWidth="1.5">
        <g>
          <g>
            <path
              d="M12.429 14.086L17.4 14.086 17.4 19.057"
              transform="translate(-215 -358) translate(132 358) translate(83) rotate(135 14.914 16.571)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
