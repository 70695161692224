/* eslint-disable prefer-promise-reject-errors */
import { APPLICATION_TOKEN } from 'utils/constants';
import { GET } from './consts';

const requestHeaders = () => ({
  'Content-Type': 'application/json',
  AUTHORIZATION: `Bearer ${localStorage.token}`,
  'api-key': APPLICATION_TOKEN,
});

const handleOptions = (data = {}) => {
  const { method = GET, headers = requestHeaders(), body } = data;

  const options = {
    method,
    headers,
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  return options;
};

export const wrapperRequest = ({ url, options }) =>
  new Promise((resolve, reject) => {
    fetch(url, handleOptions(options)).then(
      (response) => resolve(response.json()),
      (err) => reject({ type: 'main', error: err })
    );
  });
