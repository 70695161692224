import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { pipe } from 'ramda';

import { fetchApps } from 'store/apps/actions';
import { restoreSession } from 'store/session/actions';
import { fetchAlleyeProviderKPI } from 'store/alleyeDashboard/actions';
import { fetchAlleyeProviders, resetAlleyeProviders } from 'store/alleyeProviders/actions';
import { postAlleyeSales } from 'store/alleyeSales/actions';

export function useShouldUpdateApps(lastFetch) {
  const [shouldUpdate, setShouldUpdate] = useState(false);

  useEffect(() => {
    const nextFetch = new Date();
    nextFetch.setTime(new Date(lastFetch).getTime() + 60 * 60 * 1000);

    setShouldUpdate(nextFetch < new Date());
  }, [lastFetch]);

  return shouldUpdate;
}

export const useActions = () => {
  const dispatch = useDispatch();

  return {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    postAlleyeSales: useCallback(pipe(postAlleyeSales, dispatch), [postAlleyeSales, dispatch]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    resetAlleyeProviders: useCallback(pipe(resetAlleyeProviders, dispatch), [resetAlleyeProviders, dispatch]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    fetchAlleyeProviders: useCallback(pipe(fetchAlleyeProviders, dispatch), [fetchAlleyeProviders, dispatch]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    fetchAlleyeProviderKPI: useCallback(pipe(fetchAlleyeProviderKPI, dispatch), [fetchAlleyeProviderKPI, dispatch]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    fetchApps: useCallback(pipe(fetchApps, dispatch), [fetchApps, dispatch]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    restoreSession: useCallback(pipe(restoreSession, dispatch), [restoreSession, dispatch]),
  };
};
