import { useCallback, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { pipe, path } from 'ramda';

import { useAsyncState } from 'hooks';
import { wrapActions } from 'store/utils';

import { META, TASK_ID } from './consts';
import * as actions from './actions';
import { getSuccess, getError, getLoading } from './selectors';

export const useAlertsActions = wrapActions({ ...actions });

export const useAlerts = (actionCreator) => {
  const index = useRef();
  const [id, setId] = useAsyncState();

  const success = useSelector(getSuccess(id));
  const error = useSelector(getError(id));
  const loading = useSelector(getLoading(id));
  const { setCancel } = useAlertsActions();

  const extractId = useCallback(
    (action) =>
      pipe(path([META, TASK_ID]), (value) => {
        index.current = value;
        setId(value);
      })(action),
    [setId]
  );

  const resetAlerts = useCallback(() => {
    if (index.current) {
      setId();
      setCancel(index.current);
      index.current = null;
    }
  }, [setCancel, setId]);

  const handleAction = useCallback((...args) => extractId(actionCreator(...args)), [actionCreator, extractId]);

  useEffect(
    () => () => {
      if (index.current) setCancel(index.current);
    },
    [setCancel]
  );

  return {
    id,
    action: handleAction,
    extractId,
    success: id && success,
    error: id && error,
    loading: id && loading,
    resetAlerts,
  };
};
