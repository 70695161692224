import styled from 'styled-components';
import { baseTheme } from 'theme';
import Dropdown from 'assets/svg-icons/Dropdown.svg';

export const Select = styled.select`
  width: 100%;
  height: 49px;
  padding: 0 6px 0 13px;
  border-radius: 8px;
  border: solid 1px #383838;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: 0.5px;
  color: ${({ value }) => (value ? '#383838' : baseTheme.colors.font.placeholder)};
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: url(${Dropdown}) no-repeat;
  background-position: right 5px top 50%;
  margin-bottom: ${({ withBottomMargin }) => (withBottomMargin ? '10px' : 0)};

  &:focus {
    outline: 0;
  }
`;

export const Option = styled.option`
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: 0.5px;
`;
