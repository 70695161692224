const BOOTSTRAP_BREAKPOINTS_BY_SIZE = {
  // phone: 576,
  tablet: 768,
  desktop: 992,
  giant: 1200,
};

const baseBreakpoints = BOOTSTRAP_BREAKPOINTS_BY_SIZE;
const appCardWidth = 180;

const grid = {
  get layoutBreakpointMax() {
    return this.phoneMax;
  },
  gutter: 12,
  giant: baseBreakpoints.giant,
  giantMin: baseBreakpoints.giant,
  desktopMax: baseBreakpoints.giant - 1,
  desktop: baseBreakpoints.desktop,
  desktopMin: baseBreakpoints.desktop,
  tabletMax: baseBreakpoints.desktop - 1,
  tablet: baseBreakpoints.tablet,
  tabletMin: baseBreakpoints.tablet,
  phoneMax: baseBreakpoints.tablet - 1,
};

const components = {
  default: {
    borderRadius: 8,
  },
  container: {
    width: '90%',
    maxWidth: 1024,
    vMargin: 30,
  },
  input: {
    minHeight: 50,
    vPadding: 14,
    hPadding: 17,
  },
  footer: {
    height: 83,
  },
  header: {
    mobile: {
      height: 90,
    },
  },
};

export default {
  containerWidth: 920,
  containerWidthSmall: 780,
  defaultPageContentMargin: 85,
  components,
  grid,
  appCardWidth,
};
