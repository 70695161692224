import posed from 'react-pose';

export const PoseAnimateContainer = posed.div({
  open: {
    height: 'auto',
    staggerChildren: 50,
    transition: {
      duration: 100,
    },
  },
  close: {
    height: 0,
    overflow: 'hidden',
    transition: {
      duration: 50,
    },
  },
});

export const PoseAnimateItem = posed.div({
  open: { y: 0, opacity: 1 },
  close: { y: 20, opacity: 0 },
});
