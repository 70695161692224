import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { path } from 'ramda';
import PropTypes from 'prop-types';

import PrivateLayout from '../PrivateLayout';

const PrivateRoute = ({ component, ...rest }) => {
  const isAuth = useSelector(path(['session', 'user']));

  const getComponent = useCallback(
    (props) => {
      const Component = component;
      return isAuth ? (
        <PrivateLayout>
          <Component {...props} />
        </PrivateLayout>
      ) : (
        <Redirect to="/login" />
      );
    },
    [component, isAuth]
  );

  return <Route render={getComponent} {...rest} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
};

export default PrivateRoute;
