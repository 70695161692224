import styled from 'styled-components';

import { baseTheme } from 'theme';

export const Container = styled.div`
  flex: 1 1 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 50px 1fr;
  row-gap: 10px;
  width: 100%;
  height: 100%;
  padding: 10px 20px 20px;
`;

export const Head = styled.div`
  display: grid;
  grid-template-columns: 1fr 50px;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
`;

export const Button = styled.button`
  height: 50px;
  width: 50px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const Title = styled.h1`
  font-size: ${baseTheme.font.sizes.label};
  font-weight: ${baseTheme.font.weights.bold};
  text-transform: uppercase;
`;
