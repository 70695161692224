import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@medignition/medignition-ui';

import { customTheme, Spacing } from './styles';

const FormButton = ({ text, withTopMargin, ...rest }) => (
  <Spacing $withTopMargin={withTopMargin}>
    <Button text={text} customTheme={customTheme} {...rest} />
  </Spacing>
);

FormButton.defaultProps = { withTopMargin: false };
FormButton.propTypes = {
  text: PropTypes.string.isRequired,
  withTopMargin: PropTypes.bool,
};

export default FormButton;
