import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
  display: block;
  width: 100%;
  height: 46px;
  font-weight: 500;
  line-height: 1.13;
  letter-spacing: 1.06px;
  text-align: center;
  border-radius: 25px;
  background: ${({ selected }) => (selected ? '#23c47b' : '#5effb6')};
  display: flex;
  justify-content: center;
  align-items: center;
  color: #383838;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-tap-highlight-color: transparent;

  :hover {
    background: ${({ selected }) => (selected ? '#23c47b' : '#a8ffd8')};
  }
`;
