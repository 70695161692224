import { object, string } from 'yup';

export const validationSchema = object().shape({
  token: string().required("we could not find an account with the information you've have provided."),
  password: string()
    .required('password is required.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'your password does not meet the security requirements. please read the secure password guidelines below.'
    ),
});
