import { takeLatest, call } from 'redux-saga/effects';

import api from 'api';
import { withAlert } from 'store/alerts';

import { FETCH_KPI_HISTORY } from './types';

function* fetchKpiHistory({ payload }) {
  return { success: yield call(api.getKpiHistory, payload) };
}

export default function* watchKpis() {
  yield takeLatest(FETCH_KPI_HISTORY, withAlert(fetchKpiHistory));
}
