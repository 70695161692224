import styled from 'styled-components';

export const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 40px auto;
  width: 90%;
  max-width: 470px;
`;

export const LoginTitle = styled.h1`
  font-size: ${({ theme }) => theme.font.sizes.emphasized};
  color: ${({ theme }) => theme.font.colors.label};
  font-weight: normal;
  line-height: 1;
  letter-spacing: 0.5px;
  text-align: center;
  margin-bottom: 20px;
`;

export const FormContainer = styled.div`
  margin: 40px 0px;
  background: 'red';
`;

export const LinkWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
`;

export const Label = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.36;
  letter-spacing: 0.08px;
  color: ${({ theme }) => theme.colors.font.default};
  margin: 20px 0;
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Text = styled.p`
  width: 390px;
  font-size: 14px;
  line-height: 1.36;
  letter-spacing: 0.08px;
  color: ${({ theme }) => theme.colors.font.default};
  margin-bottom: 14px;
`;
