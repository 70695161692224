import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  height: 170px;
  width: 170px;
  overflow: hidden;
  cursor: pointer;
  &.online {
    background: #5effb6;

    &:hover {
      background: #a8ffd8;
    }

    &.active {
      background: #23c47b;
    }

    &.active:hover {
      background: #23c47b;
    }
  }
  &.offline {
    background: #ff5f5f;

    &:hover {
      background: #ff8080;
    }

    &.active {
      background: #ef5656;
    }

    &.active:hover {
      background: #ff8080;
    }
  }
  margin: 5px;
  border-radius: 20px;
  font-family: '"IBM Plex Sans", sans-serif';
  font-size: 15px;
  letter-spacing: 1.06px;
  padding: 11px 0;
`;

export const AppInfo = styled.div`
  position: relative;
  max-width: 80%;
  text-align: center;
  font-weight: bold;
  line-height: 1.13;
  letter-spacing: 0.77px;
  overflow-wrap: break-word;
`;

export const AppUrl = styled.a`
  color: #000;
  position: absolute;
  align-self: flex-end;
  margin: auto;
`;
