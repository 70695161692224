import { all, spawn } from 'redux-saga/effects';

import sessionSagas from './session/sessionSagas';
import appsSagas from './apps/appsSagas';
import alleyeDashboard from './alleyeDashboard/alleyeDashboardSagas';
import alleyeProviders from './alleyeProviders/alleyeProvidersSagas';
import alleyeSales from './alleyeSales/alleyeSalesSagas';
import watchKpis from './kpis/sagas';

export default function* main() {
  yield all([
    spawn(sessionSagas),
    spawn(appsSagas),
    spawn(alleyeSales),
    spawn(alleyeDashboard),
    spawn(alleyeProviders),
    spawn(watchKpis),
  ]);
}
