import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
    <g fill="none" fillRule="evenodd">
      <g stroke="#383838" strokeWidth="1.5">
        <g>
          <g transform="translate(-320 -229) translate(13 221) translate(307 8)">
            <path d="M17 17L31 31" />
            <circle cx="14" cy="14" r="10" fill="#FFF" fillRule="nonzero" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
