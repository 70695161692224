import { cond, pipe, map, flatten, juxt, applySpec, prop, ifElse, divide, __, isNil, multiply, T, equals } from 'ramda';

import { VALUE, PREV_VALUE, DATE, CHF, PERCENTAGE, ALL, YEARLY, MONTHLY } from 'store/kpis';

const getValue = (field, unit) =>
  cond([
    [() => unit === CHF, pipe(prop(field), divide(__, 100))],
    [() => unit === PERCENTAGE, pipe(prop(field), multiply(100))],
    [T, prop(field)],
  ]);

const getPreviousDate = (period) => (date) => {
  if (!date) return date;

  const d = new Date(date);

  return new Date(d.getFullYear() - (period === YEARLY ? 1 : 0), d.getMonth() - (period === MONTHLY ? 1 : 0), d.getDate());
};

export const parseData = cond([
  [
    equals(ALL),
    (period, items, unit) =>
      items.map(
        applySpec({
          group: () => 'Current',
          date: prop(DATE),
          value: getValue(VALUE, unit),
        })
      ),
  ],
  [
    T,
    (period, items, unit) =>
      pipe(
        juxt([
          map(
            applySpec({
              group: () => (period === YEARLY && 'Current year') || (period === MONTHLY && 'Current month') || 'Current',
              date: prop(DATE),
              value: getValue(VALUE, unit),
              labelValues: ($) => [
                getValue(VALUE, unit)($),
                ifElse(pipe(prop(PREV_VALUE), isNil), () => null, getValue(PREV_VALUE, unit))($),
              ],
            })
          ),
          map(
            applySpec({
              group: () => (period === YEARLY && 'Previous year') || (period === MONTHLY && 'Previous month') || 'Previous',
              date: prop(DATE),
              prevDate: pipe(prop(DATE), getPreviousDate(period)),
              value: ifElse(pipe(prop(PREV_VALUE), isNil), () => null, getValue(PREV_VALUE, unit)),
              labelValues: ($) => [
                getValue(VALUE, unit)($),
                ifElse(pipe(prop(PREV_VALUE), isNil), () => null, getValue(PREV_VALUE, unit))($),
              ],
            })
          ),
        ]),
        flatten
      )(items),
  ],
]);
