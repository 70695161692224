import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29">
    <g fill="none" fillRule="evenodd">
      <g stroke="#383838" strokeWidth="1.5">
        <g>
          <g>
            <path
              d="M12.429 9.943L17.4 9.943 17.4 14.914"
              transform="translate(-96 -358) translate(13 358) translate(83) rotate(-45 14.914 12.429)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
