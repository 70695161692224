const base = {
  line: '#979797',
  card: '#edecec',
  white: '#fff',
  blue: '#4a90e2',
};

const font = {
  default: '#383838',
  placeholder: '#b7b7b7',
  error: '#ff5f5f',
  success: '#47a77a',
  link: '#5da4ff',
  linkHover: '#8ebfff',
  linkPress: '#2e89ff',
  dropZone: '#d4d4d4',
  disabled: '#bababa',
};

const components = {
  button: {
    active: '#fff',
    background: '#f4f4f4',
    fontColor: font.default,
    disabledColor: '#eaeaea;',
    hoverColor: '#e5e4e4',
    border: '#383838',
  },
  error: '#ff5f5f',
  border: '#dcdcdc',
  spinner: {
    background: '#fff',
    body: '#5effb6',
  },
};

export default { ...base, font, components };
